import React from "react";

import { graphql, Link } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { Flex, Box } from "@rebass/grid/emotion";
import { lightbulbO } from "react-icons-kit/fa/lightbulbO";
import { magic } from "react-icons-kit/fa/magic";
import { Icon } from "react-icons-kit";

import { breadcrumbs } from "shared/helpers/schema";

import Card from "../../components/Card";
import Hero from "../../components/Hero";
import Page from "../../components/Page";
import Tile from "../../components/Tile";
import Kranicon from "../../components/Kranicon";
import Contact from "../../components/Contact";
import ProfileImage from "../../components/ProfileImage";
import RoundedImage from "../../components/RoundedImage";
import PageBlock from "../../components/PageBlock";

const HeadIcon = styled(Icon)`
  margin-right: 14px;
  transform: translateY(-3px);
`;

const Homestaging = ({ data }) => (
  <Page
    header={
      <Hero
        fluid={data.homestagingImage.fluid}
        icon={<Kranicon icon="leistungen" />}
        title="Homestaging"
        intro="Wir bereiten Ihre Immobilie professionell für Besichtigungen vor."
      />
    }
  >
    <Helmet
      title="Homestaging"
      meta={[
        {
          name: "description",
          content:
            "Wir bereiten Ihre Immobilie professionell und mit Liebe zum Detail für Besichtigungen und Verkauf vor.",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Homestaging",
              link: "/homestaging/",
              position: 1,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      <Flex justifyContent="center">
        <Box
          width={[1, null, 1 / 2]}
          css={css`
            text-align: left;
          `}
          my={5}
          px={3}
        >
          <h2>Worum geht es?</h2>
          <p>
            Nur ca. 20% aller Menschen können sich leere Räume mit ihrer
            gewünschten Einrichtung vorstellen und deren Dimensionen richtig
            abschätzen. Dabei gibt es für den ersten Eindruck keine zweite
            Chance.
          </p>
          <p>
            Beim Homestaging geht es darum, Interessenten die angebotene
            Immobilie in ihrer besten Form zu zeigen, um so der
            Vorstellungskraft etwas nachzuhelfen. Wie eine ansprechende
            Schaufenstergestaltung kann die Vorbereitung unterbewusst bei
            Besichtigungen wirken und so die Kaufentscheidung positiv
            beeinflussen.
          </p>
          <p>
            Homestaging wird schon seit den 80er Jahren in den USA,
            Großbritannien und Skandinavien mit großem Erfolg eingesetzt. Da
            sich das Prinzip nun auch hier immer größerer Beliebtheit erfreut,
            erhalten wir mehr und mehr Anfragen – aus diesem Grund bieten wir
            Homestaging nun auch neben unseren
            <Link to="/immobilie-verkaufen-thueringen/">
              {" "}
              anderen Leistungen im Verkauf
            </Link>{" "}
            offiziell als Service in der Region Weimar, Arnstadt und
            Saalfeld-Rudolstadt für Sie an.
          </p>
        </Box>
      </Flex>

      <Tile>
        <Flex alignItems="center" flexWrap="wrap" mx={-3}>
          <Box width={[1, 1 / 3]} px={3} pb={3}>
            <RoundedImage
              fluid={data.homestagingLivingRoom.fluid}
              alt="Wohnzimmer"
            />
          </Box>
          <Box width={[1, 1 / 3]} px={3} pb={3}>
            <RoundedImage fluid={data.homestagingKitchen.fluid} alt="Küche" />
          </Box>
          <Box width={[1, 1 / 3]} px={3} pb={3}>
            <RoundedImage fluid={data.homestagingCouch.fluid} alt="Sofa" />
          </Box>
        </Flex>
      </Tile>

      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 2]}>
          <Tile
            css={css`
              height: 100%;
            `}
          >
            <Card>
              <h2>
                <HeadIcon size={36} icon={lightbulbO} />
                Konzeption
              </h2>
              <p>
                Zu Beginn machen wir uns mit Ihrer Immobilie vertraut. Wen
                möchten Sie als Käuferzielgruppe ansprechen? Welche
                Besonderheiten können wir hervorheben? Was für ein Stil passt zu
                den Gegebenheiten? Gemeinsam erstellen wir ein Konzept, welches
                sowohl Ihnen zusagt als auch zukünftige Interessenten anspricht.
              </p>
              <ul>
                <li>Aufnahme der Ist-Situation</li>
                <li>Farbkonzeption</li>
                <li>Einrichtungskonzeption</li>
                <li>Planung von weiteren Maßnahmen</li>
              </ul>
            </Card>
          </Tile>
        </Box>
        <Box width={[1, 1, 1 / 2]}>
          <Tile
            css={css`
              height: 100%;
            `}
          >
            <Card>
              <h2>
                <HeadIcon size={36} icon={magic} />
                Umsetzung
              </h2>
              <p>
                Dank langjähriger Erfahrung als Makler haben wir ein großes
                Netzwerk an zuverlässigen Partnern im Handwerks- und
                Immobilienbereich aufgebaut. Zusammen statten wir Ihre Immobilie
                passend aus, führen Ausbesserungen durch und setzen das zuvor
                erstellte Konzept mit einem Auge für Details um.
              </p>
              <ul>
                <li>Tipps zur Auffrischung und direkten Umsetzung</li>
                <li>Lieferung und Aufbau von Möbeln</li>
                <li>
                  ggf. Beauftragung von Maler-, Reparatur- und
                  Reinigungsarbeiten
                </li>
                <li>liebevolle Ausgestaltung der Inneneinrichtung</li>
              </ul>
            </Card>
          </Tile>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box
          width={[1, null, 1 / 2]}
          css={css`
            text-align: left;
          `}
          my={5}
          px={3}
        >
          <h2>Was sind die Vorteile?</h2>
          <p>
            Für Eigentümer steht der Wettbewerbsvorteil im Vordergrund.
            Interessenten können sich besser mit der Immobilie identifizieren
            und sind somit bereit höhere Verkaufspreise zu zahlen. Außerdem
            verringert sich dadurch die nötige Vermarktungszeit und es ist
            günstiger, die Immobilie ansprechend vorzubereiten, als später den
            Angebotspreis zu verringern.
          </p>
          <p>
            Für Interessenten ist es angenehm den Eindruck zu erhalten, dass sie
            eine neue Immobilie besichtigen und nicht in das Zuhause von jemand
            anderes ziehen. Außerdem verstecken wir beim Homestaging nichts,
            sondern vermitteln eine realistische Vorstellung, wie die Immobilie
            im Idealfall aussehen kann.
          </p>
          <p>
            Sie sind sich noch nicht sicher ob Homestaging etwas für Sie ist
            oder haben andere Fragen?{" "}
            <Link to="/kontakt">Kontaktieren Sie uns gern</Link> und wir beraten
            Sie unverbindlich.
          </p>
        </Box>
      </Flex>

      <Tile>
        <Flex justifyContent="center">
          <Box width={[1, null, 1 / 2]}>
            <Card red>
              <ProfileImage />
              <Box mt={3}>
                <h3>Herausforderungen gemeinsam angehen</h3>
                <p>
                  Möchten Sie mit mir über Ihre Immobilie sprechen? Füllen Sie
                  dafür bitte das Formular aus. Ich melde mich danach so schnell
                  wie möglich bei Ihnen. Einfach, schnell und unverbindlich.
                </p>
                <Contact />
              </Box>
            </Card>
          </Box>
        </Flex>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    homestagingImage: imageSharp(
      fluid: { originalName: { regex: "/homestagingHero/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    homestagingLivingRoom: imageSharp(
      fluid: { originalName: { regex: "/homestagingLivingRoom/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    homestagingCouch: imageSharp(
      fluid: { originalName: { regex: "/homestagingCouch/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    homestagingKitchen: imageSharp(
      fluid: { originalName: { regex: "/homestagingKitchen/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export default Homestaging;
